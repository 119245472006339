import { Box, Flex } from '@chakra-ui/react'
import _ from 'lodash'
import { ComponentType, FC, useEffect, useMemo, useRef, useState } from 'react'
import { MpSdk } from 'shared/bundle/sdk'
import AddingItem from 'shared/components/AddingItem'
import AddingSet from 'shared/components/AddingSet'
import AmbientLight from 'shared/components/AmbientLight'
import CatalogPanel from 'shared/components/CatalogPanel'
import FloorsCalculator from 'shared/components/FloorsCalculator'
import ItemHighlight from 'shared/components/ItemHighlight'
import OutlinePass from 'shared/components/OutlinePass'
import PointLightOnCamera from 'shared/components/PointLightOnCamera'
import ProductCardModal, {
  IProductCardModal
} from 'shared/components/ProductCardModal'
import RoomShadow from 'shared/components/RoomShadow'
import RotateControlSlider from 'shared/components/RotateControlSlider'
import SelectedItemPanel from 'shared/components/SelectedItemPanel'
import TemplatesPanel from 'shared/components/TemplatesPanel'
import Tour from 'shared/components/Tour'
import TourItem from 'shared/components/TourItem'
import config from 'shared/config'
import {
  AddItemT,
  DictT,
  ItemsTemplateT,
  ItemT,
  SlotT,
  SupplierT,
  TemplatesT,
  TourSlotT
} from 'shared/types/model'
import { needShowItems } from 'shared/utils/matterport'
import { MathUtils, Vector3, Vector3Tuple } from 'three'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import CheckoutModal, {
  ICheckoutModal
} from 'shared/components/CheckoutModal'

type LocationInfoT = {
  cameraPose?: MpSdk.Camera.Pose
  // sweep?: MpSdk.Sweep.ObservableSweepData
  // rooms?: MpSdk.Room.RoomData[]
}

interface NavBarProps {
  visible: boolean
  tourId: string
  tourModelId: string
  canEdit: boolean
}

enum PanelModeT {
  CATALOG = 'CATALOG',
  TEMPLATES = 'TEMPLATES',
  USER_ITEMS = 'USER_ITEMS'
}

type CatalogModeT = {
  kind: PanelModeT.CATALOG
  selectedTourSlotId: string | null
}

type TemplatesModeT = {
  kind: PanelModeT.TEMPLATES
}

type UserItemsModeT = {
  kind: PanelModeT.USER_ITEMS
  selectedTourSlotId: string | null
}

type PanelT = CatalogModeT | TemplatesModeT | UserItemsModeT

interface UserItemsPanelProps {
  sdk: MpSdk
  tourSlots: DictT<TourSlotT>
  onAddFurnitureClick: () => void
  onAddSetClick?: () => void
  openProductCard: (item: ItemT) => void
  openCheckout: () => void
  addToRoom: (itemId: string) => void
  removeItem: (itemId: string) => void
  selectItem: (itemId: string) => void
  isCompleted?: boolean
  onMarkCompleted?: () => void
  onResetDesign?: () => void
  designName?: string
  customerLogoUrl?: string
  show?: boolean
}

type Props = {
  tourSlots: DictT<TourSlotT>
  tourId: string
  tourModelId: string
  modelId: string
  deleteSlot: (slotId: string) => void
  updateTourSlot: (
    tourSlotId: string,
    key: keyof TourSlotT,
    value: any
  ) => Promise<void>
  onResetDesign: () => void
  addItems: (items: AddItemT[]) => void
  // itemsBySlot: DictT<DictT<ItemT>>
  items: DictT<ItemT>
  slots: DictT<SlotT>
  suppliers: DictT<SupplierT>
  mattertags: DictT<string>
  onMattertagCreated: (tourSlotId: string, mtId: string) => void
  EditTourNavBar?: ComponentType<NavBarProps>
  UserItemsPanel: ComponentType<UserItemsPanelProps>
  designName?: string
  roomTypesWithTemplates: DictT<TemplatesT>
  isCompleted?: boolean
  onMarkCompleted?: () => void
  getItems?: (
    slotId: string,
    amount: number,
    offset: number
  ) => Promise<ItemT[]>
  loadItems: (itemsIds: string[]) => void
  customerLogoUrl?: string
  canEdit: boolean
  pricesVisible: boolean
  onItemLoaded?: (id: string) => void
  onUserMove?: () => void
}

const TourView: FC<Props> = ({
  tourSlots,
  tourId,
  tourModelId,
  modelId,
  deleteSlot,
  updateTourSlot,
  onResetDesign,
  addItems,
  // itemsBySlot,
  items,
  slots,
  suppliers,
  mattertags,
  onMattertagCreated,
  EditTourNavBar,
  UserItemsPanel,
  designName,
  roomTypesWithTemplates,
  isCompleted,
  onMarkCompleted,
  getItems,
  loadItems,
  customerLogoUrl,
  canEdit,
  pricesVisible,
  onItemLoaded,
  onUserMove
}) => {
  const [sdk, setSdk] = useState<MpSdk | null>(null)
  const [effectComposer, setEffectComposer] = useState<EffectComposer>()

  const locationRef = useRef<LocationInfoT>({})
  const productCardRef = useRef<IProductCardModal>(null)
  const checkoutRef = useRef<ICheckoutModal>(null)
  const [addingItemId, setAddingItemId] = useState<
    string | ItemsTemplateT | null
  >(null)
  const [moving, setMoving] = useState(false)
  const [panelMode, setPanelMode] = useState<PanelT>({
    kind: PanelModeT.USER_ITEMS,
    selectedTourSlotId: null
  })
  // const [catalogVisible, setCatalogVisible] = useState(false)
  const [viewMode, setViewMode] = useState<MpSdk.Mode.Mode>(
    'mode.inside' as MpSdk.Mode.Mode.INSIDE
  )
  const tourSlotIdRef = useRef<string | null>(null)
  const [floors, setFloors] = useState([])
  const [currentFloor, setCurrentFloor] = useState(-1)
  // const [hoveredItemId, setHoverredItemId] = useState<string | null>(null)
  const firstRender = useRef<boolean>(false)

  useEffect(() => {
    sdk && sdk.Pointer.setVisible(_.isNil(addingItemId))
  }, [sdk, addingItemId])

  useEffect(() => {
    if (!_.isNil(addingItemId)) {
      const itemsIds = _.isString(addingItemId)
        ? [addingItemId]
        : _.uniq(_.map(addingItemId.slots, s => s.itemId))
      const currentIds = _.keys(items)
      const diff = _.difference(itemsIds, currentIds)
      if (!_.isEmpty(diff)) {
        loadItems(diff)
      }
    }
  }, [addingItemId])

  const showItems = useMemo(() => {
    return needShowItems(viewMode)
  }, [viewMode])

  const toUserItemsPanel = () => {
    setPanelMode({ kind: PanelModeT.USER_ITEMS, selectedTourSlotId: null })
  }

  const selectedTourSlotId = useMemo(() => {
    let selectedSlotId = null
    if (panelMode.kind === PanelModeT.USER_ITEMS) {
      selectedSlotId = panelMode.selectedTourSlotId
    } else if (panelMode.kind === PanelModeT.CATALOG) {
      selectedSlotId = panelMode.selectedTourSlotId
    }
    tourSlotIdRef.current = selectedSlotId
    return selectedSlotId
  }, [panelMode])

  const onSlotSelect = (tourSlotId: string | null) => {
    setPanelMode(cm => {
      if (
        cm.kind === PanelModeT.USER_ITEMS &&
        cm.selectedTourSlotId === tourSlotId
      ) {
        return {
          kind: PanelModeT.USER_ITEMS,
          selectedTourSlotId: null
        }
      } else {
        return {
          kind: PanelModeT.USER_ITEMS,
          selectedTourSlotId: tourSlotId
        }
      }
    })
  }

  const onSdkReady = async (sdk: MpSdk, composer?: EffectComposer) => {
    setEffectComposer(composer)
    setSdk(sdk)

    sdk.Camera.pose.subscribe(function (pose) {
      if (pose.mode !== locationRef.current.cameraPose?.mode) {
        setViewMode(pose.mode)
      }
      locationRef.current.cameraPose = { ...pose }
    })

    sdk.Floor.current.subscribe(function (currentFloor) {
      // Change to the current floor has occurred.
      if (currentFloor.sequence === -1) {
        // console.log('Currently viewing all floors');
        setCurrentFloor(currentFloor.sequence)
      } else if (currentFloor.sequence === undefined) {
        if (currentFloor.id === undefined) {
          // console.log('Current viewing an unplaced unaligned sweep');
        } else {
          // console.log('Currently transitioning between floors');
        }
      } else {
        setCurrentFloor(currentFloor.sequence)
      }
    })
  }

  const onMoveComplete = async (p: Vector3) => {
    if (tourSlotIdRef.current) {
      const itemPosition: Vector3Tuple = [
        _.round(p.x, 3),
        _.round(p.y, 3),
        _.round(p.z, 3)
      ]
      console.log('update user item, set position', itemPosition)
      await updateTourSlot(tourSlotIdRef.current, 'position', itemPosition)
      setMoving(false)
    }
  }

  const renderTourSlots = () => {
    if (sdk && items && showItems) {
      // console.log('renderTourSlots', tour.slots)
      // console.log('currentFloor', currentFloor, floors)
      return _.map(tourSlots, (s: TourSlotT) => {
        // console.log('renderTourSlot', s)
        const itemId = s.itemId
        const item: ItemT | undefined = items[itemId]
        if (item) {
          const slot: SlotT | undefined = _.get(slots, item.kind)
          if (item && item.model && slot) {
            let isVisible = currentFloor === -1 || _.isEmpty(floors)

            if (currentFloor !== -1) {
              const floorY = _.get(floors, currentFloor)
              const floorYNext = _.get(floors, currentFloor + 1)
              if (floorY) {
                isVisible = s.position[1] > floorY
              }
              if (floorYNext) {
                isVisible = isVisible && s.position[1] < floorYNext
              }
            }
            // console.log('is visible', isVisible)
            return (
              <TourItem
                key={s.id + itemId + item.kind}
                tourId={'do_it_yourself'}
                itemId={s.itemId}
                tourSlotId={s.id}
                model={item.model}
                slotSize={slot.size}
                sdk={sdk}
                slotPosition={new Vector3().fromArray(s.position)}
                onClick={onSlotSelect}
                isSelected={selectedTourSlotId === s.id}
                rotation={s.rotation}
                canMove={moving && s.id === selectedTourSlotId}
                onMove={onMoveComplete}
                mattertagId={mattertags[s.id]}
                viewMode={viewMode}
                isHovered={false}
                // isHovered={s.id + '_' + itemId === hoveredItemId}
                onMattertagCreated={onMattertagCreated}
                visible={isVisible}
                onItemLoaded={onItemLoaded}
              />
            )
          }
        }
      })
    }
  }

  const onAddItem = (itemId: string) => {
    const sId = tourSlotIdRef.current
    if (sId) {
      updateTourSlot(sId, 'itemId', itemId)
    } else {
      setAddingItemId(itemId)
    }
  }

  const openProductCard = (item: ItemT) => {
    console.log('openProductCard', item)
    const supplier = item.supplierId && _.get(suppliers, item.supplierId)
    productCardRef.current?.open(item, supplier)
  }

  const openCheckout = () => {
    console.log('openCheckout')
    checkoutRef.current?.open()
  }

  const renderCatalogPanel = () => {
    let selectedItemId = null
    const tourSlotId = tourSlotIdRef.current
    if (tourSlotId) {
      const ts: TourSlotT = tourSlots[tourSlotId]
      if (ts) {
        selectedItemId = ts.itemId
      }
    }
    if (slots) {
      return (
        <CatalogPanel
          closeCatalogClick={toUserItemsPanel}
          addToRoom={onAddItem}
          visible={panelMode.kind === PanelModeT.CATALOG && !addingItemId}
          selectedItemId={selectedItemId}
          openProductCard={openProductCard}
          // itemsBySlot={itemsBySlot}
          getItems={getItems}
          slots={slots}
          suppliers={suppliers}
          pricesVisible={pricesVisible}
          // isEditor
        />
      )
    } else {
      console.warn('renderCatalogPanel: slots are empty')
    }
  }

  const addSetToRoom = (t: ItemsTemplateT) => {
    console.log('addSetToRoom', t)
    setAddingItemId(t)
  }

  const renderRoomTypesPanel = () => {
    if (panelMode.kind === PanelModeT.TEMPLATES) {
      return (
        <TemplatesPanel
          onClose={toUserItemsPanel}
          addToRoom={addSetToRoom}
          visible={panelMode.kind === PanelModeT.TEMPLATES && !addingItemId}
          roomTypesWithTemplates={roomTypesWithTemplates}
        />
      )
    }
  }

  const removeTourSlot = (tourSlot: TourSlotT) => {
    deleteSlot(tourSlot.id)
  }

  const removeSelectedItem = () => {
    // console.log('remove selected item')
    const tourSlotId = tourSlotIdRef.current
    if (tourSlotId) {
      const tourSlot: TourSlotT = tourSlots[tourSlotId]
      toUserItemsPanel()
      removeTourSlot(tourSlot)
    } else {
      console.warn('removeSelectedItem tourSlotId is undefined')
    }
  }

  const removeItem = (tourSlotId: string) => {
    const tourSlot: TourSlotT = tourSlots[tourSlotId]
    if (tourSlot) {
      if (
        panelMode.kind === PanelModeT.USER_ITEMS &&
        panelMode.selectedTourSlotId === tourSlot.id
      ) {
        setPanelMode({ kind: PanelModeT.USER_ITEMS, selectedTourSlotId: null })
      }
      removeTourSlot(tourSlot)
    } else {
      console.warn('removeItem tourSlot not found')
    }
  }

  const renderSelectedItemPanel = () => {
    if (selectedTourSlotId) {
      const ts: TourSlotT = tourSlots[selectedTourSlotId]
      if (ts && items) {
        const item: ItemT | undefined = items[ts.itemId]
        const catalogVisible = panelMode.kind === PanelModeT.CATALOG
        if (item) {
          return (
            <SelectedItemPanel
              tourSlotId={selectedTourSlotId}
              item={item}
              removeItem={removeSelectedItem}
              cancelSelection={toUserItemsPanel}
              onReplaceClick={() =>
                setPanelMode({ kind: PanelModeT.CATALOG, selectedTourSlotId })
              }
              catalogVisible={catalogVisible}
              openProductCard={() => openProductCard(item)}
              supplier={item.supplierId && suppliers[item.supplierId]}
            />
          )
        }
      }
    }
  }

  const onAddSetClick = () => {
    setPanelMode({ kind: PanelModeT.TEMPLATES })
  }

  const renderPanel = () => {
    if (
      panelMode.kind === PanelModeT.USER_ITEMS &&
      panelMode.selectedTourSlotId === null &&
      !addingItemId &&
      !_.isNil(sdk)
    ) {
      const firstRenderBuffer = firstRender.current
      firstRender.current = true
      return (
        <UserItemsPanel
          sdk={sdk}
          onAddFurnitureClick={() =>
            setPanelMode({ kind: PanelModeT.CATALOG, selectedTourSlotId: null })
          }
          onAddSetClick={onAddSetClick}
          openProductCard={openProductCard}
          openCheckout={openCheckout}
          addToRoom={onAddItem}
          removeItem={removeItem}
          selectItem={onSlotSelect}
          tourSlots={tourSlots}
          onResetDesign={onResetDesign}
          designName={designName}
          isCompleted={isCompleted}
          onMarkCompleted={onMarkCompleted}
          customerLogoUrl={customerLogoUrl}
          show={firstRenderBuffer}
        />
      )
    }
  }

  const applyAddingItem = (p: Vector3, rotation: number) => {
    // console.log('applyAddingItem', p, moving, selectedTourSlotId, addingItemId)
    // console.log('applyAddingItem', tourSlotIdRef.current)
    if (!addingItemId) {
      return
    }
    if (_.isString(addingItemId)) {
      const position = [
        _.round(p.x, 3),
        _.round(p.y, 3),
        _.round(p.z, 3)
      ] as Vector3Tuple
      const itemR = ((rotation + 90) % 360) - 90
      addItems([{ itemId: addingItemId, position, rotation: itemR }])
    } else if (_.isObject(addingItemId)) {
      const t: ItemsTemplateT = addingItemId as ItemsTemplateT
      const pX = _.sum(_.map(t.slots, ts => ts.position[0])) / _.size(t.slots)
      const pY = _.min(_.map(t.slots, ts => ts.position[1]))
      const pZ = _.sum(_.map(t.slots, ts => ts.position[2])) / _.size(t.slots)

      const aItems = _.map(t.slots, ts => {
        const itemP = p.clone()
        const inSetX = ts.position[0] - pX
        const inSetZ = ts.position[2] - pZ
        const r = -MathUtils.degToRad(rotation)
        const rX = inSetX * Math.cos(r) - inSetZ * Math.sin(r)
        const rZ = inSetX * Math.sin(r) + inSetZ * Math.cos(r)
        itemP.setX(_.round(itemP.x + rX, 3))
        itemP.setZ(_.round(itemP.z + rZ, 3))
        itemP.setY(_.round(itemP.y + (ts.position[1] - pY), 3))
        const itemR = (ts.rotation || 0) + rotation
        const aItem: AddItemT = {
          itemId: ts.itemId,
          position: [itemP.x, itemP.y, itemP.z],
          rotation: ((itemR + 90) % 360) - 90
        }
        return aItem
      })
      addItems(aItems)
    }

    setAddingItemId(null)
    toUserItemsPanel()
  }

  const onCancel = () => {
    setAddingItemId(null)
  }

  const renderAddingItem = () => {
    if (sdk && addingItemId) {
      if (_.isString(addingItemId)) {
        const item = _.get(items, addingItemId)
        if (item && item.kind) {
          const slot = _.get(slots, item.kind)
          if (slot) {
            return (
              <AddingItem
                key={addingItemId}
                itemId={addingItemId}
                item={item}
                slot={slot}
                sdk={sdk}
                onAddItem={applyAddingItem}
                viewMode={viewMode}
                onCancel={onCancel}
              />
            )
          }
        }
      } else if (_.isObject(addingItemId)) {
        const t: ItemsTemplateT = addingItemId as ItemsTemplateT
        console.log('return adding set')
        return (
          <AddingSet
            key={t.id}
            setId={t.id}
            items={items}
            tourSlots={t.slots}
            slots={slots}
            sdk={sdk}
            onAdd={applyAddingItem}
            viewMode={viewMode}
            onCancel={onCancel}
          />
        )
      }
    }
  }

  const renderItemHighlight = () => {
    const enabled = false
    if (enabled && sdk && effectComposer) {
      let itemId = null
      if (selectedTourSlotId) {
        const ts: TourSlotT = tourSlots[selectedTourSlotId]
        if (ts) {
          itemId = selectedTourSlotId + '_' + ts.itemId
        }
      }
      return (
        <ItemHighlight
          sdk={sdk}
          effectComposer={effectComposer}
          itemId={itemId}
        />
      )
    }
  }

  const onObjectsHover = () => {
    // setHoverredItemId(_.get(ids, 0, null))
  }

  const renderOutlinePass = () => {
    const showOutlinePass = true
    if (sdk && effectComposer && showOutlinePass) {
      let itemId = null
      if (selectedTourSlotId) {
        const ts: TourSlotT = tourSlots[selectedTourSlotId]
        if (ts) {
          itemId = selectedTourSlotId + '_' + ts.itemId
        }
      }
      return (
        <OutlinePass
          sdk={sdk}
          effectComposer={effectComposer}
          excludeItem={itemId}
          onHover={onObjectsHover}
          onClick={onSlotSelect}
          selectedTourSlotId={selectedTourSlotId}
          hideEditButton={!_.isNil(addingItemId)}
          viewMode={viewMode}
        />
      )
    }
  }

  const onRotated = (v: number) => {
    // console.log('onRotated', v, selectedTourSlotId)
    if (tourSlotIdRef.current) {
      updateTourSlot(tourSlotIdRef.current, 'rotation', v)
    }
  }

  const renderRotateControlSlider = () => {
    if (sdk && selectedTourSlotId) {
      const ts: TourSlotT = tourSlots[selectedTourSlotId]
      if (ts) {
        return (
          <RotateControlSlider
            onRotated={onRotated}
            modelRotate={ts.rotation || 0}
          />
        )
      }
    }
  }

  const renderPanels = () => {
    if (showItems && canEdit) {
      return (
        <Flex position='absolute' top='0' right='0' bottom={0} maxH={'full'}>
          {renderPanel()}
          {renderCatalogPanel()}
          {renderRoomTypesPanel()}
        </Flex>
      )
    }
  }

  console.log('SHOW ITEMS', showItems)

  return (
    <Box w='full' h='full' position='relative' overflow={'hidden'}>
      {EditTourNavBar && (
        <EditTourNavBar
          visible={!selectedTourSlotId && showItems && !_.isNil(sdk)}
          tourId={tourId}
          tourModelId={tourModelId}
          canEdit={canEdit}
        />
      )}
      <Tour
        modelId={modelId}
        applicationKey={config.applicationKey}
        onSdkReady={onSdkReady}
        hideFloorPlan={false}
        quickStart={false}
        onUserMove={onUserMove}
      />
      {sdk && showItems && <RoomShadow sdk={sdk} />}
      {sdk && showItems && <AmbientLight sdk={sdk} />}
      {sdk && showItems && <PointLightOnCamera sdk={sdk} />}
      {sdk && showItems && (
        <FloorsCalculator sdk={sdk} onFloorsReady={setFloors} />
      )}
      {canEdit && showItems && renderOutlinePass()}
      {renderAddingItem()}
      {renderItemHighlight()}
      {renderSelectedItemPanel()}
      <ProductCardModal ref={productCardRef} />
      <CheckoutModal ref={checkoutRef} />
      {renderRotateControlSlider()}
      {renderPanels()}
      {renderTourSlots()}
    </Box>
  )
}

export default TourView
